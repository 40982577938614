import React from 'react'
import Slider from 'react-slick'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

export default ({ data }) => {
  const settings = {
    customPaging: function(i) {
      return (
        <a>
          <img src={data.datoCmsWork.gallery[i].fluid.src} />
        </a>
      );
    },
    dots: data.datoCmsWork.gallery.length > 1,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <Layout>
      <article className="sheet">
        <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
        <div className="sheet__inner">
          <h1 className="sheet__title">{data.datoCmsWork.title}</h1>
          <div className="details"><span>When:</span> {data.datoCmsWork.when}</div>
          <div className="details"><span>Type:</span> {data.datoCmsWork.roletype}</div>
          <div className="details"><span>Role:</span> {data.datoCmsWork.role}</div>
          {data.datoCmsWork.toolsused ? (
          <div className="details"><span>Tools Used:</span> {data.datoCmsWork.toolsused}</div> ) : null}
          <p className="sheet__lead">{data.datoCmsWork.excerpt}</p>
          <div className="sheet__slider">
            <Slider {...settings}>
              {data.datoCmsWork.gallery.map(({ fluid }) => (
                <img alt={data.datoCmsWork.title} key={fluid.src} src={fluid.src} />
              ))}
            </Slider>
          </div>
          <div
            className="sheet__body"
            dangerouslySetInnerHTML={{
              __html: data.datoCmsWork.descriptionNode.childMarkdownRemark.html,
            }}
          />
          {/* <div className="sheet__gallery">
            <Img fluid={data.datoCmsWork.coverImage.fluid} />
          </div> */}
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsWork(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      roletype
      toolsused
      when
      role
      gallery {
        fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      coverImage {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`
